import gql from 'graphql-tag';

export const GET_ORDERS = gql`
    query AllOrders($searchText: String,$limit:Int,$offset:Int){
        AllOrders(searchText:$searchText,limit:$limit,offset:$offset){
            orderID
            orderNumber
            orderDate
            email
            tel
            name
            surname
            onlinePaymentStatus
            dateAdd
        }
        AllOrdersCount(searchText:$searchText)
    }
`;

export const GET_ORDER = gql`
    query OrderDataBy($param: String,$value:ID){
        OrderDataBy(param:$param,value:$value){
            orderID
            stosyOrderID
            customerID
            paymentTypeID
            shippingTypeID
            couponID
            paymentTypePrice
            shippingTypePrice
            lang
            orderNumber
            orderDate
            note
            company
            name
            surname
            email	
            tel
            street
            city
            zip
            ic
            dic
            icdph
            countryID
            deliveryName
            deliverySurname
            deliveryStreet
            deliveryCity
            deliveryZip
            deliveryCountryID
            couponAmount
            couponInPercentage
            couponDiscountPrice
            couponCode
            paymentType{
                title
            }
            shippingType{
                title
            }
        }
    }
`;

export const GET_ORDER_ITEMS = gql`
    query AllOrderItemsByID($orderID: ID){
        AllOrderItemsByID(orderID:$orderID){
            orderItemID
            productID
            productVariantID
            productSideID
            firstCoverID
            secondCoverID
            firstCoverPhotoID
            secondCoverPhotoID	
            count
            title
            productCode	
            priceNoVat
            price
            vat
            side	
            firstCoverTitle
            secondCoverTitle
            firstCover
            secondCover
        }
    }
`;
